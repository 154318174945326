const Mainteneance = () => {
    return (
        <>
            <h1>
                Manutenzione in corso
            </h1>
            <p>
                Torneremo presto...
            </p>
        </>
    );
}

export default Mainteneance;